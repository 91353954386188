.content-section {
  width: 100%;
  max-width: 870px;
  margin: 24px auto;
  padding: 0 24px;
  box-sizing: border-box;
}

.section-title {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #000000;
  padding: 0 8px;
}

.injury-grid {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

@media (min-width: 768px) {
  .content-section {
    margin: 32px auto;
  }

  .section-title {
    font-size: 24px;
  }
}

.section-header {
  display: flex;
  align-items: center;
}

.info-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
  font-size: 1em;
  font-weight: 700;
  color: #007aff;
  width: 20px;
  height: 20px;
  border: 2px solid currentColor;
  border-radius: 50%;
}

.info-icon:hover {
  color: #0056b3;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
  position: relative;
}

.modal-content h3 {
  margin-top: 0;
}

.modal-content ul {
  padding-left: 20px;
}

.modal-content button {
  margin-top: 15px;
  padding: 8px 16px;
  background: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-content button:hover {
  background: #444;
} 