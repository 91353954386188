.alert {
  background-color: rgba(0, 122, 255, 0.1);
  border-radius: 15px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.icon {
  margin-right: 10px;
  height: 40px;
  width: auto;
}

.headline {
  font-weight: bold;
  margin: 0;
  font-size: 24px;
  text-align: left;
}

.earnings {
  margin: 0;
  text-align: left;
  font-size: 24px;
}

.text-container {
  margin-left: 18px;
}
