:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

.header-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* max-width: 500px; */
  margin: 0 auto;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  font-family: var(--default-font-family);
  box-sizing: border-box;
  text-align: center;
  border-bottom: 1px solid #ccc;
}

.header-container .title {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.live-indicator {
  display: flex;
  align-items: center;
  background: white;
  padding: 5px 10px;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.live-dot {
  width: 14px;
  height: 14px;
  background-color: red;
  border-radius: 50%;
  margin-right: 5px;
  animation: blink 1s linear infinite;
}

.live-text {
  font-size: 14px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.live-dot {
  width: 14px;
  height: 14px;
  background-color: red;
  border-radius: 50%;
  margin-right: 5px;
  animation: blink 2s infinite;
}

.live-text {
  font-size: 16px; /* Reduced font size for better mobile display */
  font-weight: normal;
}

/* Media Queries for Larger Screens */
@media (min-width: 768px) {
  .header-container {
    flex-direction: row; /* Align items horizontally on larger screens */
    justify-content: space-between; /* Space out the title and live indicator */
    text-align: left; /* Align text to the left on larger screens */
  }

  .header-container .title {
    font-size: 36px; /* Increase title font size for larger screens */
    margin: 0; /* Remove bottom margin */
  }

  .live-indicator {
    margin-left: 28px; /* Space between the title and live indicator */
  }
}

@media (min-width: 1024px) {
  .header-container .title {
    font-size: 48px; /* Further increase title font size for very large screens */
  }
}
