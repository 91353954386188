.games-missed-container {
  @media (max-width: 442px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 442px) {
    width: 400px;
  }
  height: 320px;
  background-color: var(--secondary-background-color);
}

.games-missed-card {
  background: var(--primary-background-color);
  height: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.1);
  gap: 5px;

  @media (max-width: 442px) {
    padding: 0 30px;
  }
  padding-bottom: 10px;
}

.games-missed-card h1 {
  font-size: 31px;
  font-weight: 600;
  text-align: left;
  align-self: flex-start;
  margin-top: 28px;
  @media (min-width: 442px) {
    margin-left: 32px;
  }
}

.games-missed-count {
  font-size: 122px;
  @media (max-width: 442px) {
    font-size: 90px;
  }
  font-weight: bold;
  line-height: 1;
  max-width: 400px;
  margin: 0;
}

.games-missed-subtitle {
  font-size: 28px;
  color: var(--secondary-text-color);
  font-weight: 500;
  margin: 20px 0 0 0;
  padding: 10px 20px;
}
