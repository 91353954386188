:root {
  --primary-background-color: #ffffff;
  --secondary-background-color: #f2f2f7;
  --tertiary-background-color: #ffffff;
  --secondary-text-color: rgba(0, 0, 0, 0.5);
  --system-fill-color: rgba(229, 229, 229, 1);
  --angels-red: #ba0021;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 10px; /* Added padding for better spacing on mobile */
  box-sizing: border-box;
  padding-bottom: 70px;
  background-color: var(--secondary-background-color);
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  box-sizing: border-box;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content-container {
  display: flex;
  flex-direction: column; /* Stack items vertically on mobile */
  justify-content: center;
  align-items: center;
  margin: 24px 0;
  padding: 0 10px;
  box-sizing: border-box;
  flex-wrap: wrap;
}

/* Media Queries for Responsiveness */
@media (min-width: 768px) {
  .App-header {
    flex-direction: row;
    min-height: 50vh;
  }

  .App-logo {
    height: 30vmin;
  }

  .content-container {
    margin: 32px 0;
  }

  .salary-progress-card .taken-label,
  .salary-progress-card .taken-amount,
  .salary-progress-card .remaining-label,
  .salary-progress-card .remaining-amount {
    font-size: 26px;
  }
}

@media (min-width: 1024px) {
  .App-header {
    padding: 40px;
  }

  .content-container {
    margin: 40px 0;
  }

  .salary-progress-card .taken-label,
  .salary-progress-card .taken-amount {
    font-size: 31px;
  }

  .salary-progress-card .remaining-label,
  .salary-progress-card .remaining-amount {
    font-size: 26px;
    font-weight: 400;
  }
}
