:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

.equivalent-seasons-container {
  display: flex;
  flex-direction: column; /* Stack items vertically on mobile */
  align-items: center;
  justify-content: center;
  background: var(--primary-background-color);
  border-radius: 29px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 45px;

  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.1);
  @media (min-width: 1200px) {
    margin: 0 40px;
    width: 1160px;
    margin: auto; /* Center the container in its parent view */
  }
}

.amount-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  margin-bottom: 20px; /* Space between amount and equivalent box */
  box-sizing: border-box;
  text-align: center; /* Center text on mobile */
}

.amount {
  font-family: SF Pro, var(--default-font-family);
  font-size: 32px; /* Adjust font size for better mobile display */
  font-weight: 590;
  color: #000000;
  margin-bottom: 10px;
}

.amount-description {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.text-bold {
  font-family: SF Pro, var(--default-font-family);
  font-size: 28px; /* Adjust font size for better mobile display */
  font-weight: 400;
  color: #000000;
}

.text-normal {
  font-family: SF Pro, var(--default-font-family);
  font-size: 28px; /* Adjust font size for better mobile display */
  font-weight: 590;
  color: #000000;
}

.separator {
  width: 80%;
  height: 3px;
  background: rgba(0, 0, 0, 0.1);
  margin: 20px 0; /* Space between sections */
}

.equivalent-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.equivalent-title {
  font-family: SF Pro, var(--default-font-family);
  font-size: 28px; /* Adjust font size for better mobile display */
  font-weight: 400;
  color: #000000;
  margin-bottom: 20px;
}

.equivalent-seasons {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  justify-content: center;
  gap: 20px;
}

.season-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
  width: 150px;
  box-sizing: border-box;
}

.season-number {
  font-family: SF Pro, var(--default-font-family);
  font-size: 24px; /* Adjust font size for better mobile display */
  font-weight: 510;
  color: #000000;
  margin-bottom: 10px;
}

.season-description {
  font-family: SF Pro, var(--default-font-family);
  font-size: 16px; /* Adjust font size for better mobile display */
  font-weight: 400;
  color: #000000;
  text-align: center;
}

.arrows {
  display: none; /* Hide arrows on mobile */
}

/* Media Queries for Larger Screens */
@media (min-width: 768px) {
  .equivalent-seasons-container {
    flex-direction: row; /* Align items horizontally on larger screens */
  }

  .amount-box {
    width: 35%;
    margin-right: 30px; /* Space between amount and equivalent box */
    text-align: left; /* Align text to the left on larger screens */
  }

  .amount {
    text-align: left;
    font-size: 48px; /* Increase font size for larger screens */
  }

  .text-bold,
  .text-normal {
    text-align: left;
    font-size: 38px; /* Increase font size for larger screens */
  }

  .separator {
    width: 3px;
    height: 250px;
    margin: 0 20px; /* Space between sections */
  }

  .equivalent-box {
    align-items: flex-start; /* Align items to the start on larger screens */
    text-align: left; /* Align text to the left on larger screens */
  }

  .equivalent-title {
    font-size: 38px; /* Increase font size for larger screens */
  }

  .season-number {
    font-size: 32px; /* Increase font size for larger screens */
  }

  .season-description {
    font-size: 20px; /* Increase font size for larger screens */
  }

  .arrows {
    display: flex; /* Show arrows on larger screens */
    gap: 10px;
    position: absolute;
    right: 20px;
    bottom: 20px;
  }

  .arrow {
    width: 29px;
    height: 29px;
    background-size: cover;
  }
}
