body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

.main-container {
  overflow: hidden;
}

.main-container,
.main-container * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.main-container {
  position: relative;
  width: 384px;
  height: 265px;
  margin: 0 auto;
  font-size: 0px;
  background: #ffffff;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 4px 12px 40px 0 rgba(0, 0, 0, 0.09);
}
.text {
  display: block;
  position: relative;
  height: 25px;
  margin: 36px 0 0 29px;
  color: #000000;
  font-family: SF Pro, var(--default-font-family);
  font-size: 31px;
  font-weight: 590;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: -0.4px;
}
.group {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 29px;
  position: relative;
  width: 306.125px;
  margin: 53px 0 0 38.5px;
  z-index: 1;
}
.box {
  flex-shrink: 0;
  position: relative;
  width: 98.125px;
  height: 98.125px;
  font-size: 0px;
  z-index: 2;
}
.text-2 {
  display: block;
  position: relative;
  height: 25px;
  margin: 36px 0 0 23px;
  color: #000000;
  font-family: SF Pro, var(--default-font-family);
  font-size: 26px;
  font-weight: 700;
  line-height: 25px;
  text-align: left;
  text-decoration: underline;
  white-space: nowrap;
  letter-spacing: -0.4px;
  z-index: 5;
}
.img {
  position: absolute;
  width: 98.125px;
  height: 98.125px;
  top: 0;
  left: 0;
  background-size: cover;
  z-index: 3;
  border-radius: 50%;
}
.img-2 {
  position: absolute;
  width: 98.125px;
  height: 98.125px;
  top: 0;
  left: 0;
  background-size: cover;
  z-index: 4;
  border-radius: 50%;
}
.box-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 21px;
  position: relative;
  width: 179px;
  z-index: 6;
}
.section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 171px;
  z-index: 7;
}
.img-3 {
  flex-shrink: 0;
  position: relative;
  width: 14px;
  height: 14px;
  background-size: cover;
  z-index: 8;
  border-radius: 50%;
}
.text-3 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 22px;
  color: #000000;
  font-family: SF Pro, var(--default-font-family);
  font-size: 30px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: -0.4px;
  z-index: 9;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 10px;
  position: relative;
  width: 179px;
  z-index: 10;
}
.pic {
  flex-shrink: 0;
  position: relative;
  width: 14px;
  height: 14px;
  background-size: cover;
  z-index: 11;
  border-radius: 50%;
}
.text-4 {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 22px;
  color: #000000;
  font-family: SF Pro, var(--default-font-family);
  font-size: 30px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: -0.4px;
  z-index: 12;
}
