.created-by-container {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 20px;
}

.created-by-text {
  margin-right: 8px;
}

.creator-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  border: 1px solid black;
  border-radius: 25px;
  text-decoration: none;
  color: black;
  font-size: 12px;
  transition: background-color 0.3s;
}

.creator-button:hover {
  background-color: lightgray;
}
