:root {
  --rating-low: #EF4444;    /* Red */
  --rating-medium: #F59E0B;  /* Yellow/Orange */
  --rating-high: #10B981;    /* Green */
}

.injury-record {
  background: var(--primary-background-color);
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding-bottom: 12px;
}

.injury-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.injury-name {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.injury-duration {
  font-size: 24px;
  font-weight: 400;
}

.injury-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.injury-info {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-grow: 1;
  
}

.injury-type,
.injury-date {
  color: var(--secondary-text-color);
  font-size: 16px;
}

.injury-link {
  color: #007aff;
  text-decoration: none;
  font-size: 16px;
}

.injury-link:hover {
  text-decoration: underline;
}

.rating-section {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rating-group {
  display: flex;
  flex-direction: column; /* Stack vertically on mobile */
  align-items: flex-start; /* Align to the left */
  gap: 5px; /* Gap between stars and label on mobile */
}

@media (min-width: 768px) {
  .rating-group {
    flex-direction: row; /* Back to horizontal on desktop */
    align-items: center; /* Center align items */
    gap: 20px; /* Larger gap for desktop */
  }
}

.rating-label {
  font-size: 16px;
  color: #333;
  font-weight: 500;
}

.stars {
  display: flex;
  gap: 2px; /* Smaller gap for mobile */
  align-items: center;
}

@media (min-width: 768px) {
  .stars {
    gap: 4px; /* Larger gap for desktop */
  }
}

.star.empty {
  color: #D1D5DB;
  stroke: #D1D5DB;
}

.injury-date {
  margin-left: auto;
}
