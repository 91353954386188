.per-amount-container {
  position: relative;
  padding: 20px 10px; /* Adjusted padding for better mobile spacing */
  font-size: 0px;
  background: var(--tertiary-background-color);
  overflow: hidden;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center align items */
  width: 100%;
  min-width: 160px; /* Updated min-width for mobile */
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

@media (min-width: 745px) {
  .per-amount-container {
    min-width: 170px; /* Adjusted min-width for larger screens */
    padding: 20px 10px;
  }
}

.per-value-dollars {
  color: #000000;
  font-family: SF Pro, var(--default-font-family);
  font-size: 26px; /* Reduced font size for better mobile display */
  font-weight: 590;
  line-height: 1.2;
  text-align: center;
  white-space: nowrap;
  letter-spacing: -0.4px;
  padding: 0 10px;
}

.per-hit-subtext {
  color: var(--secondary-text-color);
  font-family: SF Pro, var(--default-font-family);
  font-size: 20px; /* Reduced font size for better mobile display */
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  white-space: nowrap;
  margin-top: 10px;
  padding: 0 10px;
}

.per-value-stat-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.per-value-stat {
  font-family: SF Pro, var(--default-font-family);
  color: var(--secondary-text-color);
  font-size: 18px; /* Reduced font size for better mobile display */
  font-weight: 470;
  line-height: 1.2;
  text-align: center;
  white-space: nowrap;
  margin-top: 5px;
  padding: 0 10px;
}

.per-value-stat-separator {
  width: 100%;
  height: 2px;
  background: rgba(0, 0, 0, 0.1);
  margin: 8px 0;
  padding: 0;
}

/* Media Queries for Larger Screens */
@media (min-width: 768px) {
  .per-value-stat-container {
    min-width: 180px; /* Set minimum width for larger screens */
  }

  .per-value-dollars {
    font-size: 32px; /* Increase font size for larger screens */
  }

  .per-hit-subtext {
    font-size: 26px; /* Increase font size for larger screens */
  }

  .per-value-stat {
    font-size: 22px; /* Increase font size for larger screens */
  }
}

@media (min-width: 1024px) {
  .per-value-dollars {
    font-size: 38px; /* Further increase font size for very large screens */
  }

  .per-hit-subtext {
    font-size: 28px; /* Further increase font size for very large screens */
  }

  .per-value-stat {
    font-size: 22px; /* Maintain size for very large screens */
  }
}
