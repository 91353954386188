.games-played-container {
  @media (max-width: 442px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 442px) {
    width: 400px;
  }
  height: 320px;
  background-color: var(--background-color);
}

.games-played-card {
  background: var(--primary-background-color);
  border-radius: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 258.1px;
  box-shadow: 0 4px 18px rgba(0, 0, 0, 0.1);
  gap: 20px;
  @media (max-width: 442px) {
    padding: 0 30px;
  }
  padding-bottom: 10px;
}

.games-played-card h1 {
  font-size: 31px;
  font-weight: 600;
  text-align: left;
  align-self: flex-start;
  margin-top: 28px;
  @media (min-width: 442px) {
    margin-left: 32px;
  }
}

.progress-container {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.games-info-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 0px;
  margin-bottom: 20px;
}

.info-item {
  display: flex;
  align-items: center;
  font-size: 30px;
}

.info-item .dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

.info-item .dot.red {
  background-color: var(--system-fill-color);
}

.info-item .dot.green {
  background-color: var(--angels-red);
}
