:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}

.earnings-section {
  width: 100%;
  background-color: var(--secondary-background-color);
  display: flex;
  justify-content: center;
  padding: 20px 10px; /* Adjusted padding for better mobile spacing */
  box-sizing: border-box;
}

.earnings-container {
  width: 100%;
  max-width: 870px; /* Ensure container does not exceed max width */
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px; /* Add padding to prevent content from touching edges */
}

.earnings-title {
  font-size: 24px; /* Adjust font size for better readability on mobile */
  font-weight: 600;
  text-align: left;
  align-self: flex-start;
  margin: 0 0 20px 0; /* Margin adjustments for spacing */
  width: 100%; /* Ensure title takes full width */
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .earnings-title {
    font-size: 32px; /* Increase font size for larger screens */
    margin: 0 0 20px 0; /* Adjust margin for larger screens */
  }

  .earnings-container {
    padding: 0; /* Remove padding for larger screens */
  }
}
