.salary-progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-background-color);
  margin: 20px 0;
  padding: 0 10px; /* Added padding to ensure content isn't too close to the edge */
}

.salary-progress-card {
  background: var(--primary-background-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 794px; /* Added max-width to maintain width on larger screens */
  box-sizing: border-box; /* Ensures padding is included in the element's total width */
}

.salary-progress-card h2 {
  font-size: 31px;
  font-weight: 600;
  margin: 0;
}

.salary-progress-card .progress-bar {
  width: 100%;
  height: 20px;
  background-color: #e0e0e0; /* Gray background for non-completed part */
  border-radius: 10px;
  overflow: hidden;
  margin-top: 15px;
  margin-bottom: 15px;
}

.salary-progress-card .progress {
  height: 100%;
  background-color: var(--angels-red);
  transition: width 0.5s ease-in-out;
}

.salary-progress-card .divider {
  margin-top: 10px;
  border: none;
  height: 2px;
  background-color: var(
    --divider-color,
    #d3d3d3
  ); /* You can set a custom divider color using a CSS variable */
}

.salary-progress-card .salary-progress-header,
.salary-progress-card .salary-progress-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.salary-progress-card .taken-label,
.salary-progress-card .taken-amount,
.salary-progress-card .remaining-label,
.salary-progress-card .remaining-amount {
  font-size: 20px;
  font-weight: 600;
  margin: 5px 0; /* Added margin for spacing */
}

/* Media Queries for Responsiveness */
@media (min-width: 768px) {
  .salary-progress-card .taken-label,
  .salary-progress-card .taken-amount,
  .salary-progress-card .remaining-label,
  .salary-progress-card .remaining-amount {
    font-size: 26px;
  }
}

@media (min-width: 1024px) {
  .salary-progress-card .taken-label,
  .salary-progress-card .taken-amount {
    font-size: 31px;
  }

  .salary-progress-card .remaining-label,
  .salary-progress-card .remaining-amount {
    font-size: 26px;
    font-weight: 400;
  }
}
