.games-summary-container {
  display: flex;
  justify-content: space-around;
  /* margin-top: 20px; */
}

.games-summary-container > * {
  flex: 1;
  margin: 0 10px;
}

@media (max-width: 895px) {
  .games-summary-container {
    flex-direction: column;
    gap: 24px;
  }
}
