.earnings-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns on mobile */
  gap: 20px;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 60px;
  padding: 0 10px; /* Added padding to ensure content isn't too close to the edge */
  box-sizing: border-box;
}

@media (min-width: 550px) {
  .earnings-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
